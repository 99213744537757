<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'app',
};
</script>

<style lang="scss">
// Import Bulma's core
@import "~bulma/sass/utilities/_all";
// Import Bulma and Buefy styles
@import "~bulma";
@import "~buefy/src/scss/buefy";
.animation-content.modal-content,.modal-content,.animation-content{
  max-width: none !important;
}
html {
  background-color: #000;
}
.navbar{height: 80px;box-shadow:0 0 0.5em 0.125em rgba(206, 46, 171, 0.08);}
.navbar-item img{height: 64px;max-height: inherit;}
.modal .animation-content {
  margin: 0 auto;
  box-shadow:none;
  width: auto;height: auto;max-width:inherit;max-height:inherit;overflow:scroll;
  }
.modal-background{background: rgb(0 0 0 / 85%);}
.modal-content{width: auto;}
.modal-card-head{ border:none}
.is-large.modal-close, .is-large.delete{background: #333;}
.button.is-primary{background-color: #DC37B8;}
.button.is-primary:hover,.button.is-primary:active, .button.is-primary.is-active{background-color:#CE2EAB}
.button.is-primary:focus:not(:active), .button.is-primary.is-focused:not(:active){box-shadow:0 0 0 0.125em rgba(206, 46, 171, 0.25)
}

</style>
