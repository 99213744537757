<template>
  <div class="pin-preview-modal"><img :src="pinItem.large_image_url" ref="image" :alt="pinItem.description" @click="toggleImageSize">
    <section>
        <div class="card">
          <div class="card-content">
            <div class="prvtags"><p class="subtitle is-6" v-show="pinItem.tags.length > 0">
              <template v-for="tag in pinItem.tags">
              <b-tag v-bind:key="tag" type="is-info" class="pin-preview-tag">{{ tag }}</b-tag>
              </template>
              <span
                      @click="closeAndGoTo"
                      class="tag pin-preview-tag is-info">
                      {{ $t("permalinkButton") }}
                </span>
              </p>
               </div>
                <h1 class="description title" v-html="niceLinks(pinItem.full_description)"></h1>
                <p><span :title="'created by '+pinItem.author" @click="closeAndGoTo">
                  {{ $t("pinnedByTitle") }} - <router-link
                    :to="{ name: 'user', params: {user: pinItem.author} }">
                    {{ pinItem.author }}
                  </router-link>
               </span></p>
            <div class="des">
                  <!--<a :href="pinItem.referer" target="_blank">
                    <b
                        v-show="pinItem.referer !== null"
                        class="meta-link"
                        type="is-warning">
                      {{ $t("sourceButton") }}
                    </b>
                  </a>
                  <a :href="pinItem.original_image_url" target="_blank">
                    <b
                        v-show="pinItem.original_image_url !== null"
                        class="meta-link"
                        type="is-link">
                        {{ $t("originalImageButton") }}
                    </b>
                  </a>-->
            </div>
          </div>
        </div>
    </section>
  </div>
</template>

<script>
import niceLinks from './utils/niceLinks';

export default {
  data() {
    return {
      imageUrl: '',
      originalWidth: 0,
      originalHeight: 0,
      enlarged: false,
    };
  },
  mounted() {
    // 获取原始图片的宽高
    this.$nextTick(() => {
      const imageRect = this.$refs.image.getBoundingClientRect();
      this.originalWidth = imageRect.width;
      this.originalHeight = imageRect.height;
    });
  },
  name: 'PinPreview',
  props: ['pinItem'],
  methods: {
    closeAndGoTo() {
      this.$parent.close();
      this.$router.push(
        { name: 'pin', params: { pinId: this.pinItem.id } },
      );
    },
    niceLinks,
    toggleImageSize() {
      // 切换图片尺寸
      if (this.enlarged) {
        // 如果已经放大，切换回原始尺寸
        this.$refs.image.style.width = `${this.originalWidth}px`;
        this.$refs.image.style.height = `${this.originalHeight}px`;
      } else {
        // 如果是原始尺寸，放大1.5倍
        this.$refs.image.style.width = `${1.5 * this.originalWidth}px`;
        this.$refs.image.style.height = `${1.5 * this.originalHeight}px`;
      }

      // 切换状态
      this.enlarged = !this.enlarged;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './utils/fonts.scss';

.meta-link {
  margin-left: 0.3rem;
}
.dim {
  @include secondary-font-color-in-dark;
}
.pin-meta-info {
  line-height: 16px;
}

.card {
  background-color: rgba(0, 0, 0, 0.6);
  .card-content {
    position: fixed;left:0;right:0;bottom:0;width:100%;text-align: center;padding-bottom:20px;
    .author {
      @include title-font-color-in-dark;
    }
    .content {
      padding: 0.3rem;
      margin-bottom: 0;
    }
    .media {
      padding: 0.3rem;
    }
  }
  .description {
    @include title-font;
    @include title-font-color-in-dark;
    font-size: 16px;
    padding: 8px;
    margin: 0;
  }
}
.pin-preview-tag {
  margin-right: 0.2rem;
  margin-bottom: 2px;
}
/* preview size should always less then screen */
.pin-preview-modal{
  img{
    max-height:inherit;
    max-width: inherit;
    height: auto;
    width: auto;
    margin: 10px auto;
  }
}
.card-image img {
  margin-left: auto;
  margin-right: auto;
  width: auto;
}
.card-content{background:rgba(0, 0, 0, 0.2);}

</style>
